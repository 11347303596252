import { createStore as createVuexStore } from 'vuex';

import tabs from './tab';

export const createStore = (app) => {
    return createVuexStore({
        modules: {
            tabbar: tabs(app),
        },
        state() {
            return {
                remember: [],
                clipboardImage: null,
                allergenes: [],
                mandants: [],
            }
        },
        mutations: {
            init(state){

            },
            setImage(state, image){
                state.clipboardImage = image;
            },
            setAllergenes(state, allergenes){
                state.allergenes = allergenes;
            },
            setMandants(state, mandants){
                state.mandants = mandants;
            },
            addRecept(state, ice) {
                state.remember.push(ice);
            },
            removeRecept(state, ice) {
                for (let index in state.remember) {
                    if (state.remember[index].id == ice.id) {
                        state.remember.splice(index, 1);
                    }
                }
            },
            clearRecepts(state) {
                state.remember = [];
            }
        }
    });
};
