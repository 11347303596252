<template>
    <div class="iceCream card h-100 user-select-none"  @click="goto()">
      <div class="ForPasteurizer" v-if="ice.flagDevelopedForPasteurizer" v-tooltip="'Dieses Rezept wurde für Pasteurisatoren entwickelt'"></div>
      <img :src="img" class="card-img-top">
      <img v-if="ice.copy_id" class="position-absolute top-0 end-0 m-1 rounded-circle shadow" style="height: 20%;" src="/img/ef_siegel.svg">
      <div class="card-body d-flex flex-column">
        <span class="card-title" :class="[small? 'small' : '']" v-if="ice.title">{{ice.title}}</span>
        <span class="card-title" :class="[small? 'small' : '']" v-else><i>unbenannt</i></span>
        <div v-if="!small" class="flex-grow-1">
            <span class="badge bg-primary me-1" v-if="ice.kind == 'milk' && ice.flagVegan == true">Vegan</span>
            <span class="badge bg-primary me-1" v-if="ice.kind == 'milk' && ice.flagVegan == false">Milcheis</span>
            <span class="badge bg-primary me-1" v-if="ice.kind == 'sorbet'">Sorbet</span>
            <span class="badge bg-secondary me-1" v-if="ice.flagVegan">Vegan</span>
            <span class="badge bg-secondary me-1" v-if="ice.flagLactose">Laktosefrei</span>
            <span class="badge bg-secondary me-1" v-if="ice.flagGlutenFree">Glutenfrei</span>
            <span class="badge bg-secondary me-1" v-if="ice.flagBio">Bio</span>
            <span class="badge bg-tertiary me-1" v-if="ice.flagPublished">Veröffentlicht</span>

            <template  v-if="ice.tags">
              <span v-for="tag in ice.tags" :key="tag.id" class="badge bg-tertiary me-1">{{tag.name}}</span>
            </template>


        </div>
          <div class="mt-1" v-if="ice.publicationDate && $auth.user().role == 'sadmin'"><small class="text-danger text-muted " style="font-size: 0.75rem; color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;">Rezeptveröffentlichung KW {{ $filters.kw(ice.publicationDate) }}</small></div>
      </div>
      <div class="card-footer" v-if="!small">
        <small v-if="!small" class="text-muted" style="font-size: 0.75rem;">zuletzt bearbeitet am {{ $filters.date(ice.updated_at) }}</small>
        <div class="row mt-1" >
            <div class="col text-end">
                <button class="btn btn-secondary btn-sm ms-1" @click.stop="mark()" title="Rezept merken" v-if="!isMarked && $auth.user().role == 'sadmin'"><i class="fa-solid fa-bookmark"></i></button>
                <button class="btn btn-primary btn-sm ms-1" @click.stop="$store.commit('removeRecept', ice)" title="Rezept merken" v-if="isMarked && $auth.user().role == 'sadmin'"><i class="fa-solid fa-bookmark-slash"></i></button>
                <button class="btn btn-tertiary btn-sm ms-1" @click.stop="deleteIcecream()" title="Rezept löschen" :disabled="!editable"><i class="fa-solid fa-trash"></i></button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import eventBus from "@/eventBus";

export default {
  name: "SingleIceCream",
  props:{
      ice: Object,
      small: Boolean,
  },
  computed: {
      editable(){
        if(this.$auth.user().role == 'sadmin') return true;
        if(this.ice.copy_id) return false;
        if(this.ice.mandant_id != this.$auth.user().mandant_id) return false;

        return true;
      },
      img(){
        if(! this.ice.imagePath && this.ice.kind == 'milk' && this.ice.flagVegan == true) {
            return require("@/assets/default_pink.jpg");
        }

        if(! this.ice.imagePath && this.ice.kind == 'milk' && this.ice.flagVegan == false) {
            return require("@/assets/default_orange.jpg");
        }

          if(! this.ice.imagePath && this.ice.kind == 'sorbet') {
              return require("@/assets/default_green.jpg");
          }


        if(! this.ice.imagePath && this.ice.imageAlternativ) return "https://icecreator.de" + this.ice.imageAlternativ;


        return "https://icecreator.de" + this.ice.imagePath;
      },
      isMarked(){
        const list = this.$store.state.remember.filter(ice => ice.id == this.ice.id);
        return list.length > 0;
      }
  },
  methods:{
      deleteIcecream(){
        if(!confirm('Rezept wirklich löschen')){
          return;
        }

        let url = "recept/" + this.ice.id.toString();
        this.saving = true;
        this.axios.delete(url).then(res => {
          eventBus.$emit("receptDelete", this.ice);
          this.saving = false;
        });
      },
      mark(){
        this.$store.commit('addRecept', this.ice);
        this.$root.showRememberList(true);
      },
      goto(){
          this.$router.push({name: "recept", "params": {id: this.ice.id}});
          this.$emit('clicked', this.ice);
      }
  }
}
</script>

<style scoped>

</style>