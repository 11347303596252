<template>
  <CModal :visible="showModal" fullscreen="fullscreen" alignment="top" @close="showModal=false">
    <CModalHeader>
      <CModalTitle>Rezept suchen</CModalTitle>
    </CModalHeader>
    <CModalBody class="p-0">
      <div class="container-fluid h-100 d-flex flex-column">
          <div class="row flex-grow-1 overflow-hidden">

                <div class="col-4 col-lg-3 col-xl-2 bg-dark h-100 d-flex flex-column py-3">
                  <perfect-scrollbar>
                  <div class="mb-3">
                    <div class="card">
                      <div class="card-header">
                        <i class="fa-solid fa-magnifying-glass"></i> Suche
                      </div>
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col">
                            <CFormInput type="text" class="form-control-sm" ref="email" id="email" placeholder="" @keyup="load" v-model="formData.search" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" v-model="formData.searchForProducts" @change="load" id="flexCheckDefault">
                              <label class="form-check-label" for="flexCheckDefault">
                                auch nach Produkten suchen
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="card">
                      <div class="card-header">
                        <i class="fa-solid fa-tags"></i> Tags
                      </div>
                      <div class="card-body">
                        <vue-tags-input
                            class="form-control form-control-sm"
                            v-model="tag"
                            :tags="formData.tags"
                            :autocomplete-items="autocompleteItems"
                            :add-only-from-autocomplete="true"
                            @tags-changed="update"
                            placeholder="Tags hinzufügen"

                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="card">
                      <div class="card-header">
                        <i class="fa-solid fa-tags"></i> Attribute
                      </div>
                      <div class="card-body">

                        <div v-if="$auth.user().role != 'sadmin'">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="formData.flagBook" id="receptSearchBook" @change="load">
                            <label class="form-check-label" for="receptSearchBook">
                              Buch-Rezepte
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="formData.flagProvided" id="receptSearchProvided" @change="load">
                            <label class="form-check-label" for="receptSearchProvided">
                              bereitgestellte Rezepte
                            </label>
                          </div>
                          <hr/>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="formData.flagVegan" id="receptSearchVegan" @change="load">
                          <label class="form-check-label" for="receptSearchVegan">
                            Vegan
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="formData.flagLactose" id="receptSearchLactose" @change="load">
                          <label class="form-check-label" for="receptSearchLactose">
                            lacotosefrei
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="formData.flagBio" id="receptSearchBio" @change="load">
                          <label class="form-check-label" for="receptSearchBio">
                            Bio
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="formData.flagGlutenFree" id="receptSearchGluten" @change="load">
                          <label class="form-check-label" for="receptSearchGluten">
                            Glutenfrei
                          </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="formData.flagDevelopedForPasteurizer" id="receptForPasteurizer" @change="load">
                            <label class="form-check-label" for="receptForPasteurizer">
                                für Pasteurisator entwickelt
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="formData.flagDevelopedForContinuousFreezer" id="receptForContinuousFreezer" @change="load">
                            <label class="form-check-label" for="receptForContinuousFreezer">
                                für Kontinuierlichen Freezer entwickelt
                            </label>
                        </div>
                        <hr />
                        <div class="form-check" v-if="$auth.user().role == 'sadmin'">
                          <input class="form-check-input" type="checkbox" v-model="formData.flagPublished" id="receptSearchPublished" @change="load">
                          <label class="form-check-label" for="receptSearchPublished">
                            Veröffentliche Rezepte
                          </label>
                        </div>
                        <div class="form-check" v-if="$auth.user().role == 'sadmin'">
                            <input class="form-check-input" type="checkbox" v-model="formData.flagWithoutImage" id="receptWithoutImage" @change="load">
                            <label class="form-check-label" for="receptWithoutImage">
                                ohne Bilder
                            </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="formData.flagUnfinished" id="receptSearchUnfinished" @change="load">
                          <label class="form-check-label" for="receptSearchUnfinished">
                            unfertige Produkte
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-3" v-if="$auth.user().role == 'sadmin'">
                    <div class="card">
                      <div class="card-header">
                        <i class="fa-solid fa-tags"></i> Mandanten
                      </div>
                      <div class="card-body">
                        <select class="form-select form-select-sm" v-model="formData.mandant" @change="load">
                          <option :value="null" >keinen Mandanten auswählen</option>
                          <option v-for="n in $store.state.mandants" :value="n" :key="n">{{ n.company }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="flex-grow-1" style="min-height: 0;" v-if="$auth.user().role == 'sadmin' && false">
                    <div class="card h-100" style="height: 100%;
    overflow: auto;">
                      <div class="card-header">
                        <i class="fa-solid fa-folders"></i> Ordner
                      </div>
                      <perfect-scrollbar class="h-100">
                      <div class="card-body">
                          <tree :nodes="nodes" :config="config"></tree>
                      </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
              </perfect-scrollbar>

                  <!--<treeselect v-model="value" class="form-control form-control-sm" :multiple="true" :options="options" />-->
                </div>

                <div class="col py-2 h-100 d-flex flex-column">
                  <perfect-scrollbar class="h-100">
                    <div class="container-fluid">
                      <div class="row" v-if="results && results.total > 0">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3" v-for="ice in results.data" :key="ice.id">
                          <single-ice-cream :ice="ice" @clicked="showModal=false"></single-ice-cream>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col alert alert-info">
                            Kein Ergebnis gefunden!
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>

          </div>
      </div>

    </CModalBody>
    <CModalFooter >

          <div class="col" v-if="results">
            <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-secondary btn-sm" :disabled="results.current_page == 1" @click="firstPage()"><i class="fa-solid fa-arrow-left-from-line"></i></button>
            <button class="btn btn-secondary btn-sm" :disabled="results.current_page == 1" @click="prevPage()"><i class="fa-solid fa-arrow-left" ></i></button>
            <select class="form-select form-select-sm"  v-model="formData.page" @change="setPage(formData.page)">
              <option v-for="n in results.last_page" :value="n" :key="n">{{ n }}</option>
            </select>
            <button class="btn btn-secondary btn-sm" :disabled="results.current_page >= results.last_page" @click="nextPage()"><i class="fa-solid fa-arrow-right"></i></button>
            <button class="btn btn-secondary btn-sm" :disabled="results.current_page >= results.last_page" @click="lastPage()"><i class="fa-solid fa-arrow-right-from-line"></i></button>
            </div>
          </div>
          <div class="col text-center" v-if="results">{{results.from}}-{{results.to}} / {{results.total}}</div>
          <div class="col text-end"><button class="btn btn-primary btn-sm" @click="showModal=false" :disabled="loading">Schließen</button></div>
    </CModalFooter>
  </CModal>
</template>

<script>
import {
  CModal, CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/bootstrap-vue";
import singleIceCream from "@/pages/common/SingleIceCream";

import { CFormFloating, CFormInput,  CFormLabel} from '@coreui/bootstrap-vue';
import Tree from "vue3-treeview";
import eventBus from "@/eventBus";
import VueTagsInput from "@sipec/vue3-tags-input";

export default {
  name: "SearchModal",
  components: {
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CFormFloating, CFormInput, CFormLabel,
    singleIceCream,
    Tree,
    VueTagsInput
  },
  props: {
    shown: Boolean
  },
  watch: {
    'tag': 'initItems',
    shown(value) {
      this.showModal = value;
    },
    showModal(value) {
      if(value) {
        this.load();
      }
      this.$emit('update:shown', value);
    }
  },
  methods: {
      update(newTags) {
        this.autocompleteItems = [];
        this.formData.tags = newTags;

        this.load();
      },
      initItems() {
        if (this.tag.length < 2) return;
        const url = "tags/autocomplete";

        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.axios.post(url, {term: this.tag}).then(response => {
            this.autocompleteItems = response.data.tags.map(a => {
              return { text: a.name, id: a.id, classes: 'created' };
            });
          }).catch(() => console.warn('Oh. Something went wrong'));
        }, 600);
      },
      load(){
          this.results = null;
          this.axios.post("recept/find", this.formData).then(res => {
              this.results = res.data;
              this.formData.page = this.results.current_page;

              if(this.results.current_page > this.results.last_page){
                this.formData.page = 1;
                this.load();
              }
          });
      },
      setPage(value){
        this.formData.page = value;
        this.load();
      },
      firstPage(){
        this.formData.page = 1;
        this.load();
      },
      lastPage(){
        this.formData.page = this.results.last_page;
        this.load();
      },
      prevPage(){
        this.formData.page -= 1;
        this.load();
      },
      nextPage(){
        this.formData.page += 1;
        this.load();
      },
  },
  mounted() {
    this.showModal = this.shown;
    eventBus.$on('receptDelete', () => {
      this.load();
    });
  },
  data() {
    return {
      tag: '',
      autocompleteItems: [],
      formData:{
          page: 1,
          search: "",
          tags: [],
          searchForProducts: false,
          flagLactose: false,
          flagGlutenFree: false,
          flagBio: false,
          flagVegan: false,
          flagPublished: false,
          flagUnfinished: false,
          flagBook: false,
          flagProvided: false,
          flagWithoutImage: false,
          flagDevelopedForPasteurizer: false,
          flagDevelopedForContinuousFreezer: false,
          mandant: null,
      },
      showModal: false,
      loading: false,
      results: null,
      mandants: [],
      config: {
        roots: ["id1", "id2", "id3", "id4", "id5", "id6", "id7", "id8", "id9", "id10", "id111", "id121"],
      },
      nodes: {
        id1: {
          text: "text1",
          children: ["id11", "id12"],
        },
        id11: {
          text: "text11",
        },
        id12: {
          text: "text12",
        },
        id2: {
          text: "text2",
        },
        id3: {
          text: "text11",
        },
        id4: {
          text: "text12",
        },
        id5: {
          text: "text11",
        },
        id6: {
          text: "text12",
        },
        id7: {
          text: "text11",
        },
        id8: {
          text: "text12",
        },
        id9: {
          text: "text11",
        },
        id10: {
          text: "text12",
        },
        id111: {
          text: "text11",
        },
        id121: {
          text: "text12",
        },
      },

      // define the default value
      value: null,
      // define options
      options: [ {
        id: 'a',
        label: 'a',
        children: [ {
          id: 'aa',
          label: 'aa',
        }, {
          id: 'ab',
          label: 'ab',
        } ],
      }, {
        id: 'b',
        label: 'b',
      }, {
        id: 'c',
        label: 'c',
      } ],
    }
  }
}
</script>

<style scoped>

</style>