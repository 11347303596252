<template>
  <CModal :visible="showModal" size="xl" alignment="top" @close="showModal=false">
    <CModalHeader>
      <CModalTitle v-if="productId">Artikel bearbeiten</CModalTitle>
      <CModalTitle v-else>Artikel anlegen</CModalTitle>
    </CModalHeader>
    <CModalBody class="p-0" v-if="product">
      <div v-if="onlyRead">
        <div class="container-fluid">
          <div class="row my-3">
            <div class="col">
              <div class="alert alert-info">
                Diese Produkte wurde Ihnen von der Firma Eisforum GmbH bereitgestellt und kann nicht bearbeitet werden. Sie können aber zur eigenen Kalkulation Ihren eigenen Preis hinterlegen.
              </div>
            </div>
          </div>
          <div class="row my-3 align-items-end">
            <div class="col-8 b-3">
              <label for="articleUrl" class="form-label">Preis
                <small class="text-muted d-block" style="font-size: 0.75rem;">(€/Kilogramm)</small>
              </label>
              <CurrencyInput v-model.lazy="product.calculationPrice" :options="options"></CurrencyInput>
            </div>
            <div class="col">
              <div class="alert alert-warning m-0">
                <i class="fa-solid fa-triangle-exclamation fa-2xl"></i> Der Eisforum Referenzpreis beträgt: <b><u>{{formatNumber(product.refPrice) }} €/kg</u></b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <ul class="nav nav-tabs px-1 pt-3" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#article-general" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Allgemein</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#article-composition" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Zusammensetzung/Nährwerte</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#article-ingredients" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Zutaten</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#article-allergens" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">Allergene</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#article-allergenicTracesOf" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">Spuren von</button>
          </li>
        </ul>
        <div class="tab-content p-3" id="myTabContent">
          <div class="tab-pane fade show active" id="article-general" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
            <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="articleTitle" class="form-label">Bezeichnung</label>
                    <input type="text" class="form-control" id="articleTitle" v-model="product.title" placeholder="Produktbezeichnung">
                  </div>
                  <div class="mb-3">
                    <label for="articleArticleNumber" class="form-label">Artikelnummer</label>
                    <input type="text" class="form-control" id="articleArticleNumber" v-model="product.articlenumber" placeholder="Artikelnummer">
                  </div>
                  <div class="mb-3">
                    <label for="articleUrl" class="form-label">Webseite/Url</label>
                    <input type="text" class="form-control" id="articleUrl" v-model="product.shopUrl" placeholder="https://www....">
                  </div>
                  <div class="mb-3" v-if="categories">
                    <label for="articleCategory" class="form-label">Artikelkategorie</label>
                    <select class="form-select" id="articleCategory" v-model="product.category_id"  aria-label="Floating label select example" >
                      <optgroup v-for="headline in categories" :key="headline.id" :label="headline.name">
                        <!--<option :value="headline.id">{{headline.name}}</option>-->
                        <option v-for="sub in headline.subcategories" :key="sub.id" :value="sub.id">{{sub.name}}</option>
                      </optgroup>
                    </select>
                  </div>
                </div>
                <div class="col-5">
                    <div class="card">
                        <div class="card-header">
                            Produktattribute
                        </div>
                        <div class="card-body">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="product.isActive" id="articleIsActive">
                            <label class="form-check-label" for="articleIsActive">
                              Aktiv
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="product.isFruit" id="articleFruitproduct">
                            <label class="form-check-label" for="articleFruitproduct">
                              Fruchtprodukt
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="product.isCalculated" id="articleIsCalculated">
                            <label class="form-check-label" for="articleIsCalculated">
                              mit berechnen
                            </label>
                          </div>
                          <div v-if="$auth.user().role == 'sadmin'">
                              <hr>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="product.isGlobal" id="articleGlobal">
                                <label class="form-check-label" for="articleGlobal">
                                  Produkt ist für alle sichtbar
                                </label>
                              </div>
                              <hr>
                              <label class="form-check-label" for="articleGlobal">
                                Produkt kopieren
                              </label>
                              <div class="btn-group" role="group" aria-label="Basic example">
                                <select class="form-select form-select-sm" v-model="copyMandant" :disabled="!product.id">
                                  <option v-for="n in $store.state.mandants" :value="n" :key="n">{{ n.company }}</option>
                                </select>
                                <button class="btn btn-secondary btn-sm" @click="copyToMandant()" :disabled="copyMandant == null"><i class="fa-solid fa-copy"></i></button>
                              </div>
                              <div v-if="!product.id" class="alert alert-info mt-1">
                                  Produkt kann erst nach dem Speichern kopiert werden.
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="tab-pane fade" id="article-composition" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
              <div class="row mb-5">
                  <div class="col b-3">
                    <label for="articleUrl" class="form-label">Wasser
                      <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                    </label>
                    <CurrencyInput v-model.lazy="product.water" :options="options"></CurrencyInput>
                  </div>
                  <div class="col b-3">
                    <label for="articleUrl" class="form-label">Zucker
                      <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                    </label>
                    <CurrencyInput v-model.lazy="product.sugar" :options="options"></CurrencyInput>
                  </div>
                  <div class="col b-3">
                    <label for="articleUrl" class="form-label">Fett
                      <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                    </label>
                    <CurrencyInput v-model.lazy="product.fat" :options="options"></CurrencyInput>
                  </div>
                  <div class="col b-3">
                    <label for="articleUrl" class="form-label">Milchtrockenmasse
                      <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                    </label>
                    <CurrencyInput v-model.lazy="product.milkSolidMass" :options="options"></CurrencyInput>
                  </div>
                  <div class="col b-3">
                    <label for="articleUrl" class="form-label">andere Trockenmasse
                      <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                    </label>
                    <CurrencyInput v-model.lazy="product.solidMass" :options="options"></CurrencyInput>
                  </div>
                <div class="col b-3">
                  <label for="articleUrl" class="form-label">Gesamttrockenmasse
                    <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                  </label>
                  <CurrencyInput v-model.lazy="product.totalSolidMass" :options="options"></CurrencyInput>
                  </div>
              </div>

            <hr class="mb-5">
            <div class="row mb-3">
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Kilojoule (kJ)
                  <small class="text-muted d-block" style="font-size: 0.75rem;">&nbsp;</small>
                </label>
                <CurrencyInput v-model.lazy="product.kilojoule" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Kalorie (Kcal)
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                </label>
                <CurrencyInput v-model.lazy="product.kilocalory" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Eiweiß
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                </label>
                <CurrencyInput v-model.lazy="product.protein" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Kohlenhydrate
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                </label>
                <CurrencyInput v-model.lazy="product.carbohydrate" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Zucker in KH
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                </label>
                <CurrencyInput v-model.lazy="product.carbohydrateSugar" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Fett
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                </label>
                <CurrencyInput v-model.lazy="product.fat" :options="options"></CurrencyInput>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col b-3">
                <label for="articleUrl" class="form-label">gesättigte Fettsäuren
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                </label>
                <CurrencyInput v-model.lazy="product.aturatedFattyAcids" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Ballaststoffe
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                </label>
                <CurrencyInput v-model.lazy="product.dietaryFiber" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Salz
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 100g)</small>
                </label>
                <CurrencyInput v-model.lazy="product.sodium" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">PAC
                  <small class="text-muted d-block" style="font-size: 0.75rem;">&nbsp;</small>
                </label>
                <CurrencyInput v-model.lazy="product.antiFreezingPower" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Negativ PAC
                  <small class="text-muted d-block" style="font-size: 0.75rem;">&nbsp;</small>
                </label>
                <CurrencyInput v-model.lazy="product.negativAntiFreezingPower" :options="options"></CurrencyInput>
              </div>
            </div>
            <div class="row">
              <div class="col b-3">
                <label for="articleUrl" class="form-label">POD
                  <small class="text-muted d-block" style="font-size: 0.75rem;">&nbsp;</small>
                </label>
                <CurrencyInput v-model.lazy="product.potereDolcificante" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Preis
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(€/Kilogramm)</small>
                </label>
                <CurrencyInput v-model.lazy="product.price" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Fruchtanteil
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in %)</small>
                </label>
                <CurrencyInput v-model.lazy="product.fruitContent" :options="options"></CurrencyInput>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Zustand
                  <small class="text-muted d-block" style="font-size: 0.75rem;">&nbsp;</small>
                </label>
                <select v-model="product.state" class="form-select form-select-sm">
                  <option value="2">trocken</option>
                  <option value="1">flüssig</option>
                </select>
              </div>
              <div class="col b-3">
                <label for="articleUrl" class="form-label">Dosiervorschlag
                  <small class="text-muted d-block" style="font-size: 0.75rem;">(in g auf 1000g)</small>
                </label>
                <input class="form-control" v-model="product.dosageSuggestion" maxlength="100" type="text">
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="article-ingredients" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
            <div class="my-3">
              <label for="exampleFormControlTextarea1" class="form-label">Produktbezeichnung in den Zutaten</label>
              <input class="form-control" id="exampleFormControlTextarea1" v-model="product.ingredientsTitle"/>
            </div>
            <div class="my-3">
              <label for="exampleFormControlTextarea1" class="form-label">Zutaten</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" v-model="product.ingredients" rows="10"></textarea>
            </div>
          </div>
          <div class="tab-pane fade" id="article-allergens" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
            <div class="row">
              <div class="col-2 mb-3" v-for="allergene in $store.state.allergenes" :key="allergene">
                <div class="btn-group d-grid" role="group" aria-label="Basic checkbox toggle button group">
                  <input type="checkbox" class="btn-check" :id="'allergene' + allergene.name" autocomplete="off" :disabled="allergene.id != 0 && product.flagAllergenNo" v-model="product['flagAllergen' + allergene.key]">
                  <label class="btn btn-outline-primary" :for="'allergene' + allergene.name">
                      <img :src="'/img/allergene/' + allergene.img" class="img-fluid my-2">
                      <div class="d-block">{{allergene.name}}</div>
                  </label>
                </div>
                <div class="text-muted small">{{ allergene.description }}</div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="article-allergenicTracesOf" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
            <div class="row">
              <div class="col-2 mb-3" v-for="allergene in $store.state.allergenes" :key="allergene">
                <div class="btn-group d-grid" role="group" aria-label="Basic checkbox toggle button group">
                  <input type="checkbox" class="btn-check" :id="'trace' + allergene.name" autocomplete="off" :disabled="allergene.id != 0 && product.flagTraceOfNo" v-model="product['flagTraceOf' + allergene.key]">
                  <label class="btn btn-outline-primary" :for="'trace' + allergene.name">
                    <img :src="'/img/allergene/' + allergene.img" class="img-fluid my-2">
                    <div class="d-block">{{allergene.name}}</div>
                  </label>
                </div>
                <div class="text-muted small">{{ allergene.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CModalBody>
    <CModalFooter class="">
      <div class="col" v-if="!onlyRead">
        <button class="btn btn-danger" v-if="modalOptions && modalOptions.deleteButton && (product && product.deleted_at == null)" @click="deleteProduct()">Produkt löschen</button>
        <button class="btn btn-danger" v-if="modalOptions && modalOptions.deleteButton && (product && product.deleted_at != null)" @click="restoreProduct()">Produkt wiederhestellen</button>
      </div>
      <div class="col text-end">
        <button class="btn btn-secondary me-1" @click="showModal=false">Abbrechen</button>
        <button class="btn btn-primary" @click="save()" v-if="!onlyRead" >Speichern</button>
        <button class="btn btn-primary" @click="savePrice()" v-else >Eigenen Preis speichern</button>
      </div>
    </CModalFooter>
  </CModal>
</template>

<script>
import {
  CModal, CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/bootstrap-vue";

import eventBus from "@/eventBus";

import CurrencyInput from "@/components/CurrencyInput";

import { CFormFloating, CFormInput,  CFormLabel} from '@coreui/bootstrap-vue';

export default {
  name: "SearchModal",
  components: {
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CFormFloating, CFormInput, CFormLabel,
    CurrencyInput
  },
  props: {
    shown: Boolean,
    productId: Number,
    modalOptions: Object,
  },
  computed: {
    onlyRead(){
      if(!this.product) return true;

      if(this.product.isGlobal && this.product.mandant_id && this.product.mandant_id != this.$auth.user().mandant_id ){
        return true;
      }

      return false;
    }
  },
  watch: {
    shown(value) {
      this.showModal = value;
    },
    showModal(value) {
      if(value) {
        if(this.modalOptions && 'createFrom' in this.modalOptions) {
          this.product = this.modalOptions.createFrom;
          this.product.id = null;
        }
        else{
          this.load();
        }
        this.loadCategories();
      }
      this.$emit('update:shown', value);
    }
  },
  methods: {
    copyToMandant(){
      this.axios.post('product/copyToMandant', {id: this.product.id, mandant: this.copyMandant}).then(res => {
        this.$toast.success('Produkt wurde erfolgreich zum Mandanten <b>' + this.copyMandant.company + '</b> kopiert!');
        this.copyMandant = null;
      });
    },
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    deleteProduct(){
        if(!confirm('Produkt wirklich löschen?')){
          return;
        }

        this.axios.post("product/delete", this.product).then((res) => {
          eventBus.$emit('productUpdated', this.product);
          this.showModal=false;
        });
    },
    restoreProduct(){
      if(!confirm('Produkt wirklich wieder herstellen?')){
        return;
      }

      this.axios.post("product/restore", this.product).then((res) => {
        eventBus.$emit('productUpdated', this.product);
        this.showModal=false;
      });
    },
    savePrice(){
      this.axios.post("product/savePrice", {id: this.product.id, price: this.product.calculationPrice}).then(res => {
        this.$emit('productUpdated', this.product);
        eventBus.$emit('productUpdated', this.product);
        this.showModal=false;
        this.$toast.success('Produktpreis wurde gespeichert!');
      });
    },
    save(){
      this.axios.post("product/save", this.product).then(res => {
        if(res.data.success == true){
          this.product = res.data.product;
          this.$emit('productUpdated', this.product);
          eventBus.$emit('productUpdated', this.product);
          this.showModal=false;
          this.$toast.success('Produkt wurde gespeichert!');
        }
      });
    },
    load(){
      this.axios.post("product/load", {id: this.productId}).then(res => {
        this.product = res.data.product;
      });
    },
    loadCategories(){
      this.axios.post("product/categories").then(res => {
        this.categories = res.data.categories;
      });
    },
  },
  mounted() {
    this.showModal = this.shown;
  },
  data() {
    return {
      product: null,
      categories: null,
      showModal: false,
      loading: false,
      copyMandant: null,
      options: {
        "currency": "EUR",
        "currencyDisplay": "hidden",
        "precision": 2,
        "hideCurrencySymbolOnFocus": true,
        "hideGroupingSeparatorOnFocus": true,
        "hideNegligibleDecimalDigitsOnFocus": true,
        "autoDecimalDigits": false,
        "useGrouping": true,
        "accountingSign": false
      },
    }
  }
}
</script>

<style scoped>

</style>