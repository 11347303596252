<script>
import {
  CFormFloating,
  CFormInput, CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from "@coreui/bootstrap-vue";

export default {
  name: "modalReceptBook",
  components:{
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CFormFloating, CFormInput,  CFormLabel,
  },
  props:{
    shown: Boolean
  },
  watch:{
    shown(value){
      this.showModal = value;
    },
    showModal(value){
      this.$emit('update:shown', value);
    }
  },
  mounted() {
    this.showModal = this.shown;
  },
  methods: {
    getBook(){
      this.axios({
        url: "/getBook",
        method: 'POST',
        responseType: 'blob',
        data: {recepts: this.$store.state.remember, headline: this.formData.headline, subheadline: this.formData.subheadline},
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', "Rezeptmappe.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();

        this.showModal = false;
      });
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      formData: {
        headline: '',
        subheadline: '',
      },
    }
  }
}
</script>

<template>
  <CModal :visible="showModal" alignment="top"  @close="showModal=false">
    <CModalHeader>
      <CModalTitle>neue Rezeptmappe erstellen</CModalTitle>
    </CModalHeader>
    <CModalBody>

      <div class="row">
        <CFormFloating class="mb-3">
          <CFormInput type="text" ref="email" id="email" placeholder="example@example.ch" v-model="formData.headline" />
          <CFormLabel for="email" ><i class="fa-solid fa-heading ms-1"></i> Headline (Optional)</CFormLabel>
        </CFormFloating>
        <CFormFloating class="mb-3">
          <CFormInput type="text" ref="email" id="email" placeholder="example@example.ch" v-model="formData.subheadline" />
          <CFormLabel for="email" ><i class="fa-solid fa-heading ms-1"></i> Subheadline (Optional)</CFormLabel>
        </CFormFloating>
      </div>

    </CModalBody>
    <CModalFooter class="text-end">
      <button class="btn btn-outline-secondary btn-sm" @click="showModal=false" :disabled="loading">Abbrechen</button>
      <button class="btn btn-primary btn-sm" @click="getBook()"><span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Rezeptmappe erstellen</button>
    </CModalFooter>
  </CModal>
</template>

<style scoped>

</style>