import { createApp } from 'vue'
import {setupI18n} from "@/i18n";
import router from './router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import auth from "@/auth";
import App from './App.vue'
import {createStore} from "@/store/store";
import VueToast from 'vue-toast-notification';

import VueDragscroll from "vue-dragscroll";
import PerfectScrollbar from 'vue3-perfect-scrollbar'

import 'moment/locale/de';
import moment from "moment";

import Treeselect from 'vue3-treeselect';
import 'vue3-treeselect/dist/vue3-treeselect.css'


import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

//import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import "vue3-treeview/dist/style.css";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';


import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import VuePdf from 'vue3-pdfjs';

import { ref } from 'vue';


export const i18n = setupI18n();

export const app = createApp(App);
const store = createStore(app);

    app.use(VuePdf)
    .use(i18n)
    .use(router)
    .use(store)
    .use(VueAxios, axios)
    .use(auth)
    .use(PerfectScrollbar)
    .use(VueToast,{
        position: 'bottom',
        duration: 6000,
    })
    .use(FloatingVue)
    .use(VueDragscroll);

    app.component('VueSlider', VueSlider)
        .component('treeselect', Treeselect)
        .component('QuillEditor', QuillEditor);
        //.component('multiselect', Multiselect);

    app.config.globalProperties.$tabs = function(){
        let tabs = function(){
            alert('Hello');
        }

        tabs.prototype.active = function(){
            console.log(this.$store);
        }

        return tabs;
    }

    app.config.globalProperties.$tab = ref(null);

    app.config.globalProperties.$filters = {
        formatCurrency(value){
            if(!value){
                value = 0.00;
            }

            return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(parseFloat(value));
            //return new Intl.NumberFormat('de-CH', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2  }).format(parseFloat(value));
        },
        date(value){
            return moment(value).format('DD.MM.YYYY');
        },
        kw(value, format){
            if(!format) format = 'W.YYYY';
            return moment(value).format(format);
        }
    }

    app.config.globalProperties.$activeTab = null;

    app.mount('#app')
