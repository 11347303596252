import {createRouter, createWebHistory} from 'vue-router';

function loadView(view) {
    return () => import(`@/pages/${view}.vue`);
}

const router = createRouter({
    hashbang: false,
    history: createWebHistory(),
    routes: [{
            path: '/login',
            name: 'login',
            component: loadView("auth/LoginForm"),
            meta: {
                auth: false,
                tab: false
            }
        },
        {
            path: '/passwort-vergessen',
            name: 'forgetPassword',
            component: loadView("auth/LoginForgetPassword"),
            meta: {
                auth: false,
                tab: false
            }
        },
        {
            path: '/',
            name: 'dashboard',
            component: loadView("start/HomeDashboard"),
            meta: {
                auth: true,
                title: "Home",
                isCache: true,
                closeable: false,
            }
        },
        {
            path: '/produkte/',
            name: 'products',
            component: loadView("article/ProductsList"),
            meta: {
                auth: true,
                title: "Produkte",
                isCache: true,
                icon: "fa-solid fa-wheat-awn",
            }
        },
        {
            path: '/bls/',
            name: 'bls',
            component: loadView("article/BlsList"),
            meta: {
                auth: true,
                title: "BLS",
                isCache: true,
                icon: "fa-solid fa-wheat-awn",
            }
        },
        {
            path: '/rezept/:id',
            name: 'recept',
            component: loadView("creator/CreatorForm"),
            meta: {
                auth: true,
                title: "Recept",
                isCache: true,
                icon: "fa-solid fa-ice-cream",
            }
        },
        {
            path: '/mandanten',
            name: 'mandants',
            component: loadView("mandant/MandantList"),
            meta: {
                auth: true,
                title: "Mandanten",
                isCache: true,
                icon: "fa-solid fa-users",
            }
        },
        {
            path: '/tags',
            name: 'tags',
            component: loadView("tags/TagsList"),
            meta: {
                auth: true,
                title: "Tags",
                isCache: true,
                icon: "fa-solid fa-tags",
            }
        },
        {
            path: '/publications',
            name: 'publications',
            component: loadView("publication/publicationList"),
            meta: {
                auth: true,
                title: "Veröffentlichungen",
                isCache: true,
                closeable: true,
                icon: "fa-solid fa-tags",
            }
        },
    ]
});

export default (app) => {
    app.router = router;
    app.use(router);
}