<template>
  <CModal :visible="showModal" alignment="top"  @close="showModal=false">
    <CModalHeader>
      <CModalTitle>neues Rezept anlegen</CModalTitle>
    </CModalHeader>
    <CModalBody>

      <div class="row">
          <CFormFloating class="mb-3">
            <CFormInput type="text" ref="email" id="email" placeholder="example@example.ch" v-model="formData.title" />
            <CFormLabel for="email" ><i class="fa-solid fa-ice-cream ms-1"></i> Rezept-Bezeichnung</CFormLabel>
          </CFormFloating>
      </div>

      <div class="row">
        <CFormFloating class="mb-3">
          <select id="kind" class="form-select" v-model="formData.kind">
              <option value="milk">Milcheis</option>
              <option value="sorbet">Sorbet</option>
          </select>
          <CFormLabel for="kind" ><i class="fa-solid fa-bowl-soft-serve ms-1"></i> Eistyp</CFormLabel>
        </CFormFloating>
      </div>
      <div class="row">
          <CFormFloating class="mb-3">            <CurrencyInput
                v-model.lazy="formData.litre"
                :options="options"
                class=""
            />
            <CFormLabel for="email"><i class="fa-solid fa-whiskey-glass-ice ms-1"></i> Rezept-Menge in Litern
            </CFormLabel>
          </CFormFloating>
        </div>
        <div class="row">
        <div class="col">
          <label>Luftaufschlag <span style="font-size: 0.8em; font-weight: normal;">(in %)</span>:
          </label>
          <vue-slider v-model="airContent" />
        </div>
      </div>

    </CModalBody>
    <CModalFooter class="text-end">
      <button class="btn btn-outline-secondary btn-sm" @click="showModal=false" :disabled="loading">Abbrechen</button>
      <button class="btn btn-primary btn-sm" :disabled="!isValid()" @click="create()"><span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Rezept anlegen</button>
    </CModalFooter>
  </CModal>
</template>

<script>
import {
  CModal, CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/bootstrap-vue";
import CurrencyInput from "@/components/CurrencyInput";

import { CFormFloating, CFormInput,  CFormLabel} from '@coreui/bootstrap-vue';

export default {
  name: "CreateModal",
  components:{
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CFormFloating, CFormInput,  CFormLabel,
    CurrencyInput
  },
  props:{
    shown: Boolean
  },
  computed:{
    airContent: {
      get() {
        return (this.formData.airContent - 1) * 100;
      },
      set(value) {
        this.formData.airContent = (value / 100) + 1;
      }
    },
  },
  watch:{
    shown(value){
        this.showModal = value;
    },
    showModal(value){
        this.$emit('update:shown', value);
    }
  },
  methods:{
    create(){
        this.loading = true;

        this.axios.post("recept/create", this.formData).then(res => {
            if(res.data.success == true){
                this.$toast.success("Juhu, dein Eis wurde angelegt");
                this.$router.push({name: "recept", params: {id : res.data.recept.id}});
                this.showModal = false;
            }
            else{
                this.$toast.error("Ein unbekannter Fehler ist aufgetreten");
            }

            this.loading = false;
        });
    },
    isValid(){
        if(this.loading){
          return false;
        }

        if(this.formData.title.trim() == ""){
          return false;
        }

        if(!(this.formData.litre > 0)){
          return 0;
        }

        return true;
    }
  },
  mounted() {
    this.showModal = this.shown;
  },
  data(){
    return {
        showModal: false,
        loading: false,
        formData: {
          title: '',
          airContent: 1.25,
          kind: "milk",
          litre: 5
        },
        options: {
          "currency": "EUR",
          "currencyDisplay": "hidden",
          "precision": 3,
          "hideCurrencySymbolOnFocus": true,
          "hideGroupingSeparatorOnFocus": true,
          "hideNegligibleDecimalDigitsOnFocus": true,
          "autoDecimalDigits": false,
          "useGrouping": true,
          "accountingSign": false
        },
    }
  }
}
</script>

<style scoped>

</style>